import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Link } from 'react-router-dom'

interface ButtonProps {
  children?: React.ReactNode,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  size?: 'xsm' | 'sm' | 'md' | 'lg',
  text?: string,
  to?: string,
  variant?: 'default' | 'secondary' | 'tertiary'
  stage?: 'default' | 'deposit' | 'harvest'
  image?: boolean, 
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  variant,
  stage,
}) => {
  const { color, spacing } = useContext(ThemeContext)

  let buttonColor: string
  switch (variant) {
    case 'secondary':
      buttonColor = color.white
      break
    case 'default':
    default:
      buttonColor = color.grey[600]
      // buttonColor = color.primary.main
  }

  let boxShadow: string
  let buttonSize: number
  let buttonPadding: number
  let fontSize: number
  switch (size) {
    case 'xsm':
      boxShadow = `2px 2px 4px ${color.green[300]}`
      buttonPadding = spacing[3]
      buttonSize = 18
      fontSize = 14
      break
    case 'sm':
      boxShadow = `3px 3px 6px ${color.green[300]}`
      buttonPadding = spacing[3]
      buttonSize = 26
      fontSize = 14
      break
    case 'lg':
      boxShadow = `3px 3px 6px ${color.green[300]}`
      buttonPadding = spacing[4]
      buttonSize = 72
      fontSize = 14
      break
    case 'md':
    default:
      boxShadow = `3px 3px 6px ${color.green[300]}`
      buttonPadding = spacing[4]
      buttonSize = 45
      fontSize = 14
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>
    } else if (href) {
      return <StyledExternalLink href={href} target="__blank">{text}</StyledExternalLink>
    } else {
      return text
    }
  }, [href, text, to])

  return (
    <StyledButton
      boxShadow={boxShadow}
      color={buttonColor}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
    >
      {children}
      {ButtonChild}
    </StyledButton>
  )
}

interface StyledButtonProps {
  boxShadow: string,
  color: string,
  disabled?: boolean,
  fontSize: number,
  padding: number,
  size: number,
}
 
const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background-color: #5498c6;
  border: 1;
  border-color: #ffffff;
  border-radius: 4px;
  box-shadow: ${props => props.boxShadow};
  color: ${props => !props.disabled ? props.color : `${props.color}`};
  cursor: pointer;
  display: flex;
  opacity: 1;
  font-size: ${props => props.fontSize}px;
  font-weight: 700;
  height: ${props => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${props => props.padding}px;
  padding-right: ${props => props.padding}px;
  pointer-events: ${props => !props.disabled ? undefined : 'none'};
  &:hover {
    background-color: #71b8e9;
    }
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button