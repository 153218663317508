import React, { useState } from 'react'
import styled from 'styled-components'
import FlipwatchLogo from '../../assets/flipwatchLogo.png'
import Flipwatch from '../../assets/Flipwatchsmall.png'
import ImageDark1 from '../../assets/dark1.png'
import ImageDark2 from '../../assets/dark2.png'
import { NavLink } from 'react-router-dom'

interface TopBarProps {
  darkToggle: React.Dispatch<boolean>
  darkMode: boolean
}

const TopBar: React.FC<TopBarProps> = ({ darkToggle, darkMode }) => {


{/* // Contact dropdown display handling
  const [active, setActive] = useState(false);

  function handleMouseEnter() {
    setActive(true);
  }
  function handleMouseLeave() {
    setActive(false);
  }
*/}
const toggleDarkMode = () => {
  console.log('before toggle: ' + darkMode)
  darkToggle(!darkMode);
  console.log('after toggle: ' + darkMode)
};

  return (
    <StyledTopBar>
      <StyledLogo to="/" title="Homepage"/>


      <StyledDarkMode>
        {darkMode ? (
          <img src={ImageDark1} onClick={toggleDarkMode} alt="Vin Mode" title="Vin Mode"/>
        ) : (
          <img src={ImageDark2} onClick={toggleDarkMode} alt="Vin Mode" title="Vin Mode"/>
        )}
      </StyledDarkMode>

      <StyledSpacer>
        <StyledNav>
          <StyledAbout to="/about" title="About FlipWatch">
            About
          </StyledAbout>
          <StyledText to="/" title="Homepage"/>
          <StyledContact>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSc-kSxqqMlLYypDQFmF1TB9MsUbdly7cxxJm3z_u5WTnlPOOA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" title="Contact Form">Contact</a>
          </StyledContact>
        </StyledNav>
      </StyledSpacer>

{/* Contact Dropdown box
        <StyledContact onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          Contact
          {active && 
            <StyledDropdown>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSc-kSxqqMlLYypDQFmF1TB9MsUbdly7cxxJm3z_u5WTnlPOOA/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Form</a><br />
            </StyledDropdown>
          }
        </StyledContact>
*/}


      
    </StyledTopBar>
  )
}

const StyledTopBar = styled.div`

`
const StyledDarkMode = styled.div`
  position: absolute;
  top: 10px;
  left: 50px;
  width: 50px;
  height: 50px;
  border: 0px solid red
`
const StyledSpacer= styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
  background-color: #20acac;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  box-shadow: 0px 7px 10px rgba(0,0,0,0.5);
`
const StyledAbout= styled(NavLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-size: 30px;
  font-weight: 700;
  border: 0px solid red;
  width: 150px;
  :hover {
    color: #6bffdb;
  }
`
const StyledContact= styled.div`
  display: flex;
  font-size: 30px;
  font-weight: 700;
  color: #ffffff;
  border: 0px solid red;
  width: 150px;
  justify-content: center;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  a:hover {
    color: #6bffdb;
  }
`
const StyledDropdown = styled.div`
  position: absolute;
  top: 50px;
  background-color: #20acac;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  color: #ffffff;
  font-size: 24px;
  padding-left: 10px;
  a {
    text-decoration: none;
    color: #ffffff;
  }
  a:hover {
    color: #6bffdb;
  }
`
const StyledNav= styled.div`
display: flex;
flex-wrap: wrap;
position: relative;
gap: 20px;
justify-content: center;
align-items: center;
  margin: 0 auto;
  border: 0px solid red;
  text-decoration: none;
`
const StyledText = styled(NavLink)`
display: flex;
  width: 299px;
  height: 100px;
  background-image: url(${Flipwatch});
  background-size: contain;
  background-repeat: no-repeat;
  border: 0px solid blue;
  margin: 0 auto;
  z-index: 2;
  justify-content: center;
  align-items: center;
  position: relative;

`
const StyledLogo = styled(NavLink)`
  display: flex;
  width: 200px;
  height: 200px;
  background-image: url(${FlipwatchLogo});
  background-size: 100% 100%;
  border: 0px solid pink;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`
export default TopBar
