export const white = '#FFF'
export const black = '#000'
export const lightHover = '#d6eeee'
export const darkHover = '#606060'
export const darkCard = '#606060'
export const lightCard = '#ffffff'

export const green = {
  100: '#00ffa6', // BCH Green
  200: '#a0fedd', // BCH Green Highlight
  300: '#008d5c', // BCH Green Darklight
  500: '#24ffb3', // button hover
}

export const red = {
  100: '#FFFDFE',
  200: '#ffc2a8',
  500: '#d16c00',
}

export const blue = {
  100: '#00b5d2', //button hover
}

export const grey = {
  100: '#303030',
  200: '#f0e9e7',
  300: '#e2d6cf',
  400: '#ffffff', // Labels + ?
  500: '#805e49',
  600: '#ffffff', // Other Labels + ?
  700: '#131515', // Card Background
  800: '#2d1e12',
  900: 'rgba(7.5, 8.2, 8.2, 0.3)',
  1000: 'rgba(7.5, 8.2, 8.2, 0)',
}
