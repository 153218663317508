import { black, green, grey, white, blue, lightHover, darkHover, lightCard, darkCard} from './colors'

const theme = {
  light: {
    background: white,
    text: black,
    rowHover: lightHover,
    cardBackground: lightCard,
    borderRadius: 12,
    breakpoints: {
      mobile: 400,
    },
    color: {
      black,
      grey,
      green,
      blue,
      primary: {
        light: grey[100],
        main: grey[500],
      },
      secondary: {
        main: green[500],
      },
      white,
    },
    siteWidth: 1200,
    spacing: {
      1: 4,
      2: 8,
      3: 16,
      4: 24,
      5: 32,
      6: 48,
      7: 64,
      8: 0,
      9: 100,
      10: 150
    },
    topBarSize: 150
  },
  dark: {
    background: grey[100],
    text: white,
    rowHover: darkHover,
    cardBackground: darkCard,
    borderRadius: 12,
    breakpoints: {
      mobile: 400,
    },
    color: {
      black,
      grey,
      green,
      blue,
      primary: {
        light: grey[100],
        main: grey[500],
      },
      secondary: {
        main: green[500],
      },
      white,
    },
    siteWidth: 1200,
    spacing: {
      1: 4,
      2: 8,
      3: 16,
      4: 24,
      5: 32,
      6: 48,
      7: 64,
      8: 0,
      9: 100,
      10: 150
    },
    topBarSize: 150
  },
}

export default theme