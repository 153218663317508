import React from 'react';
import styled from 'styled-components';

interface ReportCardProps {
  promisedDeliver: string
  didDeliver: string
  promisedUpdates: string
  didUpdates: string
  promisedAccountable: string
  didAccountable: string
  promisedTimeline: string
  didTimeline: string
}

const ReportCard: React.FC<ReportCardProps> = ({
  promisedDeliver,
  didDeliver,
  promisedUpdates,
  didUpdates,
  promisedAccountable,
  didAccountable,
  promisedTimeline,
  didTimeline
}) => {
  return (
    <GridContainer>
      <OddRow><Question>Did they promise to deliver verifiable goods or services?</Question><Status>{promisedDeliver}</Status></OddRow>
      <EvenRow><Question>If so did they deliver on the promise?</Question><Status>{didDeliver}</Status></EvenRow>
      <OddRow><Question>Did they promise to give regular updates?</Question><Status>{promisedUpdates}</Status></OddRow>
      <EvenRow><Question>If so did they meet update targets?</Question><Status>{didUpdates}</Status></EvenRow>      
      <OddRow><Question>Did they promise some form of accountability?</Question><Status>{promisedAccountable}</Status></OddRow>
      <EvenRow><Question>If so did they meet accountability targets?</Question><Status>{didAccountable}</Status></EvenRow>      
      <OddRow><Question>Did they provide a delivery timeline?</Question><Status>{promisedTimeline}</Status></OddRow>
      <EvenRow><Question>If so, did they meet the deliery timeline?</Question><Status>{didTimeline}</Status></EvenRow>
    </GridContainer>
  );
};


const GridContainer = styled.div`
  border: 1px solid black;
  padding: 1px;
  display: grid;
  width: 100%;
  grid-template-columns: 10fr 1fr; // Adjust the ratio as per your requirement
  grid-template-rows: repeat(8, 1fr); // Creates 8 rows
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const Question = styled.div`
  border-right: 1px solid black;
  margin-left: 2px;
  `;
const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GridRow = styled.div`
  display: contents;
`;
const OddRow = styled(GridRow)`
  & > ${Question}, & > ${Status} {
    background-color: #27c0c0; 
  }
`;
const EvenRow = styled(GridRow)`
  & > ${Question}, & > ${Status} {
    background-color: #20acac;
  }
`;

  const Tooltip = styled.span`
  display: none;
  position: absolute;
  background-color: #27cfcf;
  color: #fff;
  text-align: center;
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
  z-index: 1;
  font-size: 16px;
  width: 300px; // Set fixed width here
  right: 100%; // Position the tooltip to the left
  top: 0%; // Align the tooltip vertically
  margin-right: 0px; // Add some margin between the tooltip and the cell
  line-height: 16px;
`;

const StyledHeaderCell = styled.th`
  background-color: #5498c6;
  border: 1px solid black;
  padding: 0px 0px;
  text-align: left;
  color: #fff;
  font-weight: bold;
  text-align: center;
  position: relative;
  line-height: 0.7;

  &:hover  {
    background-color: #71b8e9;
  }
  &:hover ${Tooltip} {
    display: inline;
  }
`;




export default ReportCard;