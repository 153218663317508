import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Flipstarter from './components/Flipstarter'
import Papa from 'papaparse'


const Flipstarters: React.FC = () => {

  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState(["expired", "unknown", "undelivered", "caution", "partial", "delivered", "refunded"]);
{/* Google Sheets API Columns config
    const satoshis = 0
    const bch = 1
    const title = 2
    const description = 3
    const url = 4
    const fundedTx = 5
    const categories = 6
    const announcements = 7
    const archives = 8
    const screenshots = 9
    const date = 10
    const usd = 11
    const recipients = 12
    const status = 13
*/}

const setStatus = (status: string) => {
  if (statuses.includes(status)) {
    setStatuses(statuses.filter((s) => s !== status));
  } else {
    setStatuses(statuses.concat(status));
  }
}

    useEffect(() => {
      async function fetchData() {
        try {
          const response = await fetch('https://flipwatch.org/database.csv')
          const text = await response.text();
          const results = Papa.parse(text, { header: true });
          setData(results.data);
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    }, [])

    {/*                             Google Sheets API config
  useEffect(() => {
    async function fetchData() {
      // Production Sheet
      const response = await fetch(
        'https://sheets.googleapis.com/v4/spreadsheets/12rRs5CI6Be-PhZIVIjqgDVvHJmZPAJzHgv4yhzc2SmQ/values/Sheet1?key=' + apiKey
      );
      // Test Sheet
      const response = await fetch(
        '' + apiKey
      );
      const json = await response.json();
      setData(json.values);
    }
    fetchData();
  }, []);
*/}

let dataLength;
if (data) {
  dataLength = data.length;
}
// create array of flipstarter card components for each row in the spreadsheet

const [flipCards, setFlipCards] = useState([]);
useEffect(() => {
  // create a new array of Flipstarter components
  const newFlipCards = data
    .map((row, index) => <Flipstarter 
      key={index}
      index={index}
      bch={row.bch} 
      title={row.title} 
      description={row.description} 
      url={row.url}
      fundedTx={row.fundedTx} 
      categories={row.categories}
      announcements={row.announcements}
      archive={row.archives}
      screenshots={row.screenshots}
      date={row.date}
      usd={row.usd_amount}  
      recipients={row.recipient}  
      status={row.status}
      otherflips={row.otherflips}
      promisedDeliver={row.promisedDeliver}
      didDeliver={row.didDeliver}
      promisedUpdates={row.promisedUpdates}
      didUpdates={row.didUpdates}
      promisedAccountable={row.promisedAccountable}
      didAccountable={row.didAccountable}
      promisedTimeline={row.promisedTimeline}
      didTimeline={row.didTimeline} />)

      newFlipCards.reverse();
  // update the state with the new array
  setFlipCards(newFlipCards);
}, [statuses, data]);
{/*
const flipCards: Array<any> = []
useEffect(() => {
  // sort everything newest to oldest
  for (var row = (data.length - 2); row >= 0; row--) {
    if (statuses.includes(data[row].status)) {
      flipCards.push(<Flipstarter 
        key={row}
        index={row}
        bch={data[row].bch} 
        title={data[row].title} 
        description={data[row].description} 
        url={data[row].url}
        fundedTx={data[row].fundedTx} 
        categories={data[row].categories}
        announcements={data[row].announcements}
        archive={data[row].archives}
        screenshots={data[row].screenshots}
        date={data[row].date}
        usd={data[row].usd_amount}  
        recipients={data[row].recipient}  
        status={data[row].status}
        otherflips={data[row].otherflips} />)
    }
  }
}, [statuses])
*/}

// get total number of each category flipstarter status
let fundedFlips = 0;
let expiredFlips = 0;
let deliveredFlips = 0;
let partialFlips = 0;
let cautionFlips = 0;
let undeliveredFlips = 0;
let unknownFlips = 0;
let refundedFlips = 0;
  data.map((row, index) => {
      if (row.status !== 'expired') {  // if not expired status count as funded, else its expired
        fundedFlips++;
      } else {
        expiredFlips++;
      } 
      if (row.status === 'delivered') {     // check for each status
        deliveredFlips++;
      }
      if (row.status === 'partial') {      
        partialFlips++;
      }
      if (row.status === 'caution') {
        cautionFlips++;
      }
      if (row.status === 'undelivered') {
        undeliveredFlips++;
      }
      if (row.status === 'unknown') {
        unknownFlips++;
      }
      if (row.status === 'refunded') {
        refundedFlips++;
      }
  });

  const percentFunded = ((fundedFlips / dataLength) * 100)
  const percentDelivered = ((deliveredFlips / fundedFlips) * 100)
  const percentPartial = ((partialFlips / fundedFlips) * 100)
  const percentCaution = ((cautionFlips / fundedFlips) * 100)
  const percentUndelivered = ((undeliveredFlips / fundedFlips) * 100)
  const percentExpired = ((expiredFlips / fundedFlips) * 100)
  const percentUnknown = ((unknownFlips / fundedFlips) * 100)
  const percentRefunded = ((refundedFlips / fundedFlips) * 100)

  // console.log('dataLength: ' + dataLength)
  // console.log(statuses)
   console.log(flipCards)
  return (
    <StyledPage>
      <StyledStats>

        <StyledTitle>Recorded Flipstarters</StyledTitle>
        <StyledBar>
          <StyledTotalBar>Expired: {expiredFlips}</StyledTotalBar>
          <StyledValueBar style={{ width: `${percentFunded}%`, backgroundColor: '#20acac', color: '#ffffff' }}>Funded: {fundedFlips}</StyledValueBar>
        </StyledBar>

        <StyledTitle>FlipWatch status of Flipstarters</StyledTitle>
        <StyledBar>
          <StyledResultsBar>
            {statuses.includes('expired') ? (
              <StyledValueBar2 title="Expired" style={{ width: `${percentExpired}%`, backgroundColor: '#6a00ff', color: '#ffffff' }} onClick={() => setStatus('expired')}> {expiredFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Expired" style={{ width: `${percentExpired}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('expired')}> {expiredFlips}</StyledValueBar2>
            )}
            {statuses.includes('unknown') ? (
              <StyledValueBar2 title="Unknown" style={{ width: `${percentUnknown}%`, backgroundColor: '#696969', color: '#ffffff' }} onClick={() => setStatus('unknown')}> {unknownFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Unknown" style={{ width: `${percentUnknown}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('unknown')}> {unknownFlips}</StyledValueBar2>
            )}
            {statuses.includes('undelivered') ? (
              <StyledValueBar2 title="Undelivered" style={{ width: `${percentUndelivered}%`, backgroundColor: '#a60000', color: '#ffffff' }} onClick={() => setStatus('undelivered')}> {undeliveredFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Undelivered" style={{ width: `${percentUndelivered}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('undelivered')}> {undeliveredFlips}</StyledValueBar2>
            )}
            {statuses.includes('refunded') ? (
              <StyledValueBar2 title="Refunded" style={{ width: `${percentRefunded}%`, backgroundColor: '#ff7e12', color: '#ffffff' }} onClick={() => setStatus('refunded')}> {refundedFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Refunded" style={{ width: `${percentRefunded}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('refunded')}> {refundedFlips}</StyledValueBar2>
            )}
            {statuses.includes('caution') ? (
              <StyledValueBar2 title="Caution" style={{ width: `${percentCaution}%`, backgroundColor: '#bac100', color: '#ffffff' }} onClick={() => setStatus('caution')}> {cautionFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Caution" style={{ width: `${percentCaution}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('caution')}> {cautionFlips}</StyledValueBar2>
            )}
            {statuses.includes('partial') ? (
              <StyledValueBar2 title="Partial" style={{ width: `${percentPartial}%`, backgroundColor: '#0087ff', color: '#ffffff' }} onClick={() => setStatus('partial')}> {partialFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Partial" style={{ width: `${percentPartial}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('partial')}> {partialFlips}</StyledValueBar2>
            )}
            {statuses.includes('delivered') ? (
              <StyledValueBar2 title="Delivered" style={{ width: `${percentDelivered}%`, backgroundColor: '#20acac', color: '#ffffff' }} onClick={() => setStatus('delivered')}> {deliveredFlips}</StyledValueBar2>
            ) : (
              <StyledValueBar2 title="Delivered" style={{ width: `${percentDelivered}%`, backgroundColor: '#000000', color: '#ffffff' }} onClick={() => setStatus('delivered')}> {deliveredFlips}</StyledValueBar2>
            )}
            
            
            
          </StyledResultsBar>
        </StyledBar>

      </StyledStats>
      <StyledTimeline />

      <StyledFlipstarters>
        Flipstarters Over Time
      </StyledFlipstarters>
  
      <StyledCards>
      {flipCards.map(row => {
        if (statuses.includes(row.props.status)) {
          return row;
        }
      })}
      </StyledCards>

    </StyledPage>
  )
}

const StyledFlipstarters = styled.div`
  margin: 30px auto;
  display: flex;
  height: 50px;
  width: 300px;
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  border: 0px solid #000000;
  box-shadow: inset 0px 0px 5px rgba(0,0,0,0.5);
  position: relative;
`
const StyledCards = styled.div`
  border: 0px solid blue;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  position: relative;
`
const StyledTimeline = styled.div`
  position: absolute;
  left: 50%;
  top: 195px;
  border: 0px solid red;
  width: 5px;
  height: 100%;
  z-index: 0;
  background-color: white;
  box-shadow: inset 0px 0px 4px rgba(0,0,0,0.5);
`
const StyledPage = styled.div`
  color: ${props => props.theme.text};
  position: relative;
  border: 0px solid red;
  margin: 0px auto;
  justify-content: center;
  width: 100%;
  height: 100%;
`
const StyledTitle = styled.div`
  display: flex;
  border: 0px solid red;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
`
const StyledStats = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  margin: 0px auto;
  justify-content: center;
  width: 80%;
`
const StyledBar = styled.div`
  border: 0px solid grey;
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StyledTotalBar = styled.div`
  border: 2px solid #000000;
  height: 20px;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: right;
  padding-right: 5px;
`
const StyledValueBar = styled.div`
  border-right: 2px solid #000000;
  height: 20px;
  position: absolute;
  margin-top: 2px;
  margin-left: 2px;
  padding-left: 5px;
`
const StyledResultsBar = styled.div`
  border: 2px solid #000000;
  height: 20px;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: right;
  display: flex;
`
const StyledValueBar2 = styled.div`
  border-right: 2px solid #000000;
  height: 20px;
  :hover {
    opacity: 0.6;
  }
  :hover::after {
    content: attr(title);
    position: absolute;
    bottom: -30px;
    background-color: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
  }
`

export default Flipstarters
