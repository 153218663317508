import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import TopBar from './components/TopBar'
import Flipstarters from './components/Flipstarters'
import Footer from './components/Footer'
import About from './components/About'
import Detailed from './components/Detailed'
import styled from 'styled-components'

const App: React.FC = () => {
  const [darkMode, setDarkMode] = useState(false);
  

  return (
    <ThemeProvider theme={darkMode ? theme.light : theme.dark}>
      <StyledBackground>
      <Router>
        <TopBar darkToggle={setDarkMode} darkMode={darkMode} />

        <Switch>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/:number">
            <Detailed />
          </Route>
          <Route path="/">
            <Flipstarters />
          </Route>
        </Switch>

      </Router>

      <Footer />
      </StyledBackground>
    </ThemeProvider>
  );
}

const StyledBackground = styled.div`
  background: ${props => props.theme.background};
`
export default App;
