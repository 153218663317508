import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components'

const FAQ: React.FC = () => {
  const [fileText, setFileText] = useState(''); 

   // Read txt file from server into variable for rendering
   useEffect(() => {
    fetch('https://flipwatch.org/doc/qa.htm')
      .then((response) => response.text())
      .then((text) => setFileText(text));
  }, []);

  return (
    <FAQPage>
      <StyledText dangerouslySetInnerHTML={{ __html: fileText }} />
    </FAQPage>
  );
};

const FAQPage = styled.div`
  color: ${props => props.theme.text};
  max-width: 800px;
  padding: 10px;
  margin: 1rem auto;
  display: flex;
  flex-wrap: wrap;
`
const StyledText = styled.div`
  position: relative;
  border: 0px solid yellow;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 800px;
  top: -50px;
  font-size: 18px;
  line-height: 20px;
`
const StyledAccordion = styled.div`
  position: relative;
  border: 0px solid red;
  text-align: left;
  width: 800px;
  top: -100px;
`
const AccordionTitle = styled.div`
  background: ${props => props.theme.background};
  background-size: 800px 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;
  border: 1px solid #20acac;
  &:hover {
    background: #6bffdb;
  }
`
const StyledSection = styled.div`
  display: flex;
  justify-content: center;
  font-size: 28px;
  margin-bottom: 10px;
  border: solid 1px #000000;
  background-color: #20acac;
  background-size: 13px 100%;
  color: #ffffff;
  &:hover {
    background: #3ac6c6;
  }
`
const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  min-width: 250px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px solid ${props => props.theme.color.green[100]}ff;
`
const AccordionContent = styled.div`
  font-family: calibri;
  background: ${props => props.theme.background};
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-size: cover;
`

export default FAQ