import React from 'react'
import styled from 'styled-components'
import TelegramLogo from '../../assets/telegram.png'
import TwitterLogo from '../../assets/twitter.png'
import ReadCashLogo from '../../assets/readcash.png'
import RedditLogo from '../../assets/reddit.png'
import YoutubeLogo from '../../assets/youtube.png'
import Button from '../Button'
import ReportCard from '../ReportCard'
import { NavLink, Route, useRouteMatch } from 'react-router-dom'

interface ModalProps {
  index: number
  title: string
  description: string
  categories: string
  announcements: string
  archive: string
  promisedDeliver: string
  didDeliver: string
  promisedUpdates: string
  didUpdates: string
  promisedAccountable: string
  didAccountable: string
  promisedTimeline: string
  didTimeline: string
}

const Modal: React.FC<ModalProps> = ({
  index, 
  title, 
  description, 
  categories, 
  announcements, 
  archive, 
  promisedDeliver,
  didDeliver,
  promisedUpdates,
  didUpdates,
  promisedAccountable,
  didAccountable,
  promisedTimeline,
  didTimeline }) => {

  const announcementsObject = JSON.parse(announcements);
  const { path } = useRouteMatch()

  return (
    <StyledInfo>
      <StyledTitle>{title}</StyledTitle>
      <StyledRowData>
        <StyledColumnLeft>

        <StyledDescription>
                {description ? (
              description
            ) : (
              'No Description 😔'
            )}
          </StyledDescription>

          <StyledAnnouncements>
            <StyledAnnTitle>Initial Announcements</StyledAnnTitle>
            <StyledLogos>
              {announcementsObject.map((announcement: any) => (
                <div key={announcement.url}>
                  {announcement.url.includes('https://t.me') && (
                    <a href={announcement.url} target="_blank"><img src={TelegramLogo} alt=""/></a>
                  )}
                  {announcement.url.includes('https://twitter.com') && (
                    <a href={announcement.url} target="_blank"><img src={TwitterLogo} alt=""/></a>
                  )}
                  {announcement.url.includes('https://read.cash') && (
                    <a href={announcement.url} target="_blank"><img src={ReadCashLogo} alt=""/></a>
                  )}
                  {announcement.url.includes('reddit.com') && (
                    <a href={announcement.url} target="_blank"><img src={RedditLogo} alt=""/></a>
                  )}
                  {announcement.url.includes('youtube.com') && (
                    <a href={announcement.url} target="_blank"><img src={YoutubeLogo} alt=""/></a>
                  )}
                </div>
              ))}
            </StyledLogos>
          </StyledAnnouncements>

          <StyledDetailButton to={`/${index}`} target="_blank" rel="noopener noreferrer">
            <Button>Detailed Writeup</Button>
          </StyledDetailButton> 

        </StyledColumnLeft>

        <StyledColumnRight>
          <StyledReportCard>
             <ReportCard 
                promisedDeliver={promisedDeliver}
                didDeliver={didDeliver}
                promisedUpdates={promisedUpdates}
                didUpdates={didUpdates}
                promisedAccountable={promisedAccountable}
                didAccountable={didAccountable}
                promisedTimeline={promisedTimeline}
                didTimeline={didTimeline}
             />
          </StyledReportCard>
        </StyledColumnRight>

      </StyledRowData>
    </StyledInfo>
  );
};


const StyledInfo = styled.div`
  background: #20acac;
  color: #ffffff;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: row;
  position: relative;
  width: 80%;
  border: 1px solid #000000;
  border-radius: 10px;
  min-height: 100px;
  max-height: 100vh
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const StyledTitle = styled.div`
  background: ${props => props.theme.cardBackground};
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-wrap: row;
  justify-content: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #000000;
  border-radius: 5px 5px 0 0;
  height: 30px;
`
const StyledRowData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  border: 0px solid yellow;
  min-height: 100px;
`
const StyledColumnLeft = styled.div`
  background: #20acac;
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 50%;
  min-width: 400px;
  min-height: 100px;
  border: 0px solid red;
  border-radius: 0px;
  margin-left: 5px;
  color: #ffffff;
`
const StyledColumnRight = styled.div`
  background: #20acac;
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 50%;
  min-width: 400px;
  min-height: 100px;
  max-height: 100vh
  border: 0px solid yellow;
  border-radius: 0px;
  margin-right: 5px;
  color: #ffffff;
`
const StyledLogos = styled.div`
  align-items: left;
  display: flex;
  flex-direction: row
  flex-wrap: wrap;
  gap: 5px;
  justify-content: left;
  position: relative;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  width: 95%;
  background: #ffffff;
  min-height: 40px;
  img {
    width: 40px;
  }
`
const StyledReportCard = styled.div`
  border: 0px solid orange;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  align-items: flext-start;
  width: 98%;
  min-height: 100px;
`
const StyledDescription = styled.div`
  border: 0px solid blue;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  align-items: flext-start;
  width: 98%;
  height: 100%;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
const StyledDetailButton = styled(NavLink)`
  margin-top: 5px;
  border: 0px solid blue;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 150px;
  bottom: 60px;
  align-self: center;
  text-align: center;
  justify-content: center;
  width: 40%;
  height: 50px;
  text-decoration: none;
`
const StyledAnnouncements = styled.div`
  border-bottom: 0px solid #000000;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
`
const StyledAnnTitle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: #000000;
  padding-left: 10px;
  border-bottom: 0px solid #000000;
  background: #20acac;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
`
const StyledScreenshots = styled.div`
  border: 0px solid #000000;
  width: 97%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
`

export default Modal;
