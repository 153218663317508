import React from 'react'
import styled from 'styled-components'
import QRcode from '../../assets/qrCode.png'
import ImageTwitter from '../../assets/twitter.png'
import ImageTelegram from '../../assets/telegram.png'

const Footer: React.FC = () => (
  <StyledFooter>
    <StyledFooterInner>
      <StyledDonation>
        <span style={{fontSize: '20px'}}>Donate</span><br/>
        <StyledQR />
        bitcoincash:qrf050e4ds004mlyynhflnrq4mj44mnfrsmwdt7uyd
      </StyledDonation>
      <StyledSocials>
        <StyledTwitter><a href="https://twitter.com/FlipwatchOrg" target="_blank"><img src={ImageTwitter} style={{height: '40px'}}/></a></StyledTwitter>
        <StyledTelegram><a href="https://t.me/flipwatch" target="_blank"><img src={ImageTelegram} style={{height: '40px'}}/></a></StyledTelegram>
    </StyledSocials>
    </StyledFooterInner>

  </StyledFooter>

)

const StyledFooter = styled.footer`
  margin-top: 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  height: 200px;
  width: 100%;
  position: relative;
  box-shadow: 0px 7px 10px rgba(0,0,0,0.5);
  background-color: #20acac;
`
const StyledFooterInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0px solid black;
  width: 100%;
`
const StyledDonation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 0px dashed red;
  line-height: 15px;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  width: 500px;
`
const StyledQR = styled.div`
  margin-top: 5px;
  background-image: url(${QRcode});
  background-size: 100% 100%;
  border: 1px solid black;
  border-radius: 6px 6px 6px 6px;
  width: 150px;
  height: 150px;
`
const StyledSocials = styled.div`
  display: flex;
  position: relative;
  right: 120px;
  height: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border: 0px solid red;
  img {
    border-radius: 20px 20px 20px 20px;
  }
`
const StyledTwitter = styled.div`
  img {
    border: 1px solid black;
    border-radius: 20px 20px 20px 20px;
  }
  img:hover {
    border: 1px solid #ffffff;
    border-radius: 20px 20px 20px 20px;
  }
`
const StyledTelegram = styled.div`
  img {
    border: 1px solid black;
    border-radius: 20px 20px 20px 20px;
  }
  img:hover {
    border: 1px solid #ffffff;
    border-radius: 20px 20px 20px 20px;
  }
`
export default Footer