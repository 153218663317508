import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import ImagePin from '../../../assets/pin.png'
import ImagePinOpen from '../../../assets/pinOpen.png'
import ImageBCH from '../../../assets/bch.png'
import Modal from '../../Modal'

interface FlipstarterProps {
    index: number
    bch: number
    title: string
    description: string
    url: string
    fundedTx: string
    categories: string
    announcements: string
    archive: string
    screenshots: string
    date: string;
    usd: number;
    recipients: string;
    status: string;
    otherflips: Array<string>
    promisedDeliver: string
    didDeliver: string
    promisedUpdates: string
    didUpdates: string
    promisedAccountable: string
    didAccountable: string
    promisedTimeline: string
    didTimeline: string
}

const Flipstarter: React.FC<FlipstarterProps> = ({
    index, 
    bch, 
    title,
    description, 
    url, 
    fundedTx, 
    categories,
    announcements,
    archive,
    screenshots,
    date,
    usd,
    recipients,
    status,
    otherflips,
    promisedDeliver,
    didDeliver,
    promisedUpdates,
    didUpdates,
    promisedAccountable,
    didAccountable,
    promisedTimeline,
    didTimeline
 }) => {
// convert recipients spreadsheet string to array of recipients. ignore first row (titles)
    //let recipientsObject;
   // if (index != 0) {
    let recipientsObject = JSON.parse(recipients)
   // }
// flip cards back and forth between sides
    const side = index % 2 === 0 ? false : true;
// piece together link for fund tx hash link
    let fundedSplice
    let fundedLink
    if (fundedTx !== '') {
        fundedSplice = (((fundedTx).slice(0,8) + '...' + (fundedTx).slice(56,64)) || 'notdefined')
        fundedLink = (('https://explorer.melroy.org/tx/' + fundedTx) || 'notdefined')
    }
// create date in proper format for each entry
    const formatDate = new Date(date);
    const day = (formatDate.getDate()) + 1; // extract the day (why 1 lower only sometimes wtf)
    const restDate = formatDate.toLocaleDateString('en-US', {
        month: 'short',
        year: 'numeric'
    }); // format the rest of the date (Mar, 2020)

const [isInfoOpen, setIsInfoOpen] = useState(false);
  const toggleInfo = () => {
    setIsInfoOpen(!isInfoOpen);
  };
const [isRowHover, setIsRowHover] = useState(false);
  const toggleHover = () => {
    setIsRowHover(!isRowHover);
  };

let screenshot = '';
    //dynamically create local image path based on database and import it into project for displaying
    let screenshotsObject
    if (screenshots.startsWith('[{"image":')) {
    screenshotsObject = JSON.parse(screenshots);
        if (screenshotsObject[0].image.includes('flipbackend.bitcoincash.network')) {
            screenshot = '../../../img/small/' + screenshotsObject[0].image.slice(57);
        } else if ((screenshotsObject[0].image.includes('flipwatch.org'))) {
            screenshot = '../../../img/small/' + screenshotsObject[0].image.slice(25);
        }
    }
    console.log(didAccountable)
    return (
    <>
        {side ? (
            <StyledRow onClick={toggleInfo} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                <StyledFlipstarter>
                    <StyledHeader>
                        <StyledTitle>{title}</StyledTitle>
                        <StyledStatus><img src={require(`../../../assets/${status}.png`)} height="80px" width="80px" alt="" /></StyledStatus>
                    </StyledHeader>
                    <StyledContent>
                        Recipient:
                        {recipientsObject.map((recipient: any) => (
                            <StyledRecipient key={recipient.url}>
                                {recipient.url}
                            </StyledRecipient>
                        ))}
                        <br />
                        <StyledBCH>
                            <img src={ImageBCH} style={{height: '30px', width: '30px'}} alt="" /> {bch}
                        </StyledBCH>
                        USD Raised: ${usd}<br />
                        <StyledFunded>
                            Funded Tx: 
                                {fundedTx !== '' && (
                                    <><a href={fundedLink} target="_blank">{fundedSplice}</a><br /></>
                                )}
                                {fundedTx === '' && (
                                    <>expired</>
                                )}
                        </StyledFunded>
                    </StyledContent>
{/*
                    {categories.length < 2 ? (
                        categories
                    ) : (
                        categories.map(category => category + ' ')
                    )}<br />            
*/}

                </StyledFlipstarter>      

                {isRowHover || isInfoOpen ? (
                    <StyledMiddleHover />
                ) : (
                    <StyledMiddle />
                )}
                <StyledDateHolder side={side}>
                    <StyledDateBackground background={screenshot}/>
                    <StyledDate side={side}>
                        <StyledDD>{day}</StyledDD>
                        <StyledMMYY>{restDate}</StyledMMYY>
                    </StyledDate>
                </StyledDateHolder>

                <StyledPadding />
            </StyledRow>
        ) : (
            <StyledRowRight onClick={toggleInfo} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                <StyledPadding />

                <StyledDateHolder side={side}>
                    <StyledDateBackground background={screenshot}/>
                    <StyledDate side={side}>
                        <StyledDD>{day}</StyledDD>
                        <StyledMMYY>{restDate}</StyledMMYY>
                    </StyledDate>
                </StyledDateHolder>
                {isRowHover || isInfoOpen ? (
                    <StyledMiddleHover />
                ) : (
                    <StyledMiddle />
                )}

                <StyledFlipstarterRight>
                    <StyledHeader>
                        <StyledTitle>{title}</StyledTitle>
                        <StyledStatus><img src={require(`../../../assets/${status}.png`)} height="80px" width="80px" alt="" /></StyledStatus>
                    </StyledHeader>
                    <StyledContent>
                        Recipient: 
                        {recipientsObject.map((recipient: any) => (
                            <StyledRecipient key={recipient.url}>
                                {recipient.url}
                            </StyledRecipient>
                        ))}
                        <br />
                        <StyledBCH>
                            <img src={ImageBCH} style={{height: '30px', width: '30px'}} alt="" /> {bch}
                        </StyledBCH>
                        USD Raised: ${usd}<br />
                        <StyledFunded>
                            Funded Tx:
                            {fundedTx !== 'expired' && (
                                    <><a href={fundedLink} target="_blank">{fundedSplice}</a><br /></>
                                )}
                                {fundedTx === 'expired' && (
                                    <>expired</>
                            )}
                        </StyledFunded>
                    </StyledContent>
{/*
                    {categories.length < 2 ? (
                        categories
                    ) : (
                        categories.map(category => category + ' ')
                    )}<br />
*/}
                </StyledFlipstarterRight> 
            </StyledRowRight>

        )}
    {isInfoOpen ? (
        <Modal 
            index={index}
            title={title}
            description={description}
            categories={categories}
            announcements={announcements}
            archive={archive}
            promisedDeliver={promisedDeliver}
            didDeliver={didDeliver}
            promisedUpdates={promisedUpdates}
            didUpdates={didUpdates}
            promisedAccountable={promisedAccountable}
            didAccountable={didAccountable}
            promisedTimeline={promisedTimeline}
            didTimeline={didTimeline}
        /> 
    ) : (
        <></>
    )}
    </>
  )
}
type StyledDateHolderProps = {
    side: boolean;
  };
type StyledDateBackgroundProps = {
    background: string;
  };
type StyledDateProps = {
    side: boolean;
  };

//  troubleshooting using local paths for images for backgrounds
  const logProp = (prop: any) => {
    console.log(prop);
    return prop;
  }

const HeaderGradient = keyframes `
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 0px solid red;
    position: relative;
    gap: 5px;
    padding: 5px;
    &:hover {
        border: 1px solid #20acac;
        box-shadow: 0px 0px 10px rgba(12.5,67.5,67.5,0.5);
        background: ${props => props.theme.rowHover};
        padding: 4px;
    }
`
const StyledRowRight = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 0px solid green;
    position: relative;
    gap: 5px;
    padding: 5px;
    &:hover {
        border: 1px solid #20acac;
        box-shadow: 0px 0px 10px rgba(12.5,67.5,67.5,0.5);
        background: ${props => props.theme.rowHover};
        padding: 4px;
    }
`
const StyledMiddle = styled.div`
    display: flex;
    position: absolute;
    top: 70px;
    width: 60px;
    height: 60px;
    background-image: url(${ImagePin});
    background-size: 100% 100%;
    border: 0px solid orange;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: 50px;
`
const StyledMiddleHover = styled.div`
    display: flex;
    position: absolute;
    top: 70px;
    width: 60px;
    height: 60px;
    background-image: url(${ImagePinOpen});
    background-size: 100% 100%;
    border: 0px solid orange;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    border-radius: 50px;
`
const StyledDateHolder = styled.div<StyledDateHolderProps>`
    display: flex;
    position: relative;
    border: 0px solid red;
    background-size: cover; 
    opacity: 1.0;
    width: 350px;
    height: 180px;
    justify-content: center;
    left: ${props => (props.side ? '90px' : '-90px')};
    align-items: center;
    box-shadow: 0px 0px 0px rgba(0,0,0,0.5);
    clip-path: inset(0px 0px -5px 0px);
    z-index: 1;
`
const StyledDateBackground = styled.div<StyledDateBackgroundProps>`
    display: flex;
    position: relative;
    border: 0px solid blue;
    background-image: ${props => `url(${props.background})`};
    background-size: cover; 
    opacity: 1.0;
    width: 100%;
    height: 100%;
    z-index: 2;
`
const StyledDate = styled.div<StyledDateProps>`
    display: flex;
    position: absolute;
    background: linear-gradient(-45deg, #4bf7f7, #0fb8b8, #20acac, #20acac);
    border: 1px solid black;
    opacity: 1.0;
    width: 240px;
    height: 60px;
    left: ${props => (props.side ? '0px' : '')};
    right: ${props => (props.side ? '' : '0px')};
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
    clip-path: inset(0px 0px -5px 0px);
    z-index: 10;
`
const StyledDD = styled.div`
    display: flex;
    border: 0px solid orange;
    width: 75px;
    height: 60px;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: 700;

    background: linear-gradient(45deg, #4bf7f7, #0fb8b8, #20acac, #20acac);
    color: #ffffff;
    z-index: 10;
`
const StyledMMYY = styled.div`
    display: flex;
    border: 0px solid pink;
    width: 165px;
    height: 60px;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
`
const StyledFlipstarter = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    right: 0px;
    overflow: clip;
    margin: 0 auto;
    justify-content: left;
    align-items: left;
    width: 350px;
    margin-top: 0px;
    border: 1px solid #000000;
    border-radius: 5px;
    overflow: visible;
    background: ${props => props.theme.cardBackground};
`
const StyledFlipstarterRight = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    left: 0px;
    overflow: clip;
    margin: 0 auto;
    justify-content: left;

    width: 350px;
    margin-top: 0px;
    border: 1px solid #000000;
    border-radius: 5px;
    overflow: visible;
    background: ${props => props.theme.cardBackground};
`
const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;

  font-size: 18px;
  font-weight: 700;
  border-bottom: 1px solid #000000;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  overflow: visible;
  background: linear-gradient(-45deg, #20acac, #4affcd, #3affc9, #20acac);
  background-size: 400% 400%;
  :hover {
    animation: ${HeaderGradient} 4s ease infinite;
  }
`
const StyledTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border: 0px solid blue;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #ffffff;
`
const StyledContent= styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    margin: 0 auto;
    padding: 5px;
    font-weight: 700;
`
const StyledFunded = styled.div`
    display: flex;
    width: 100%;
    height: 20px;
    border: 0px solid orange;
    margin: 0 auto;
    a {
        color: #20acac;
        text-decoration: none;
      }
      a:hover {
        color: #3affc9;
        text-decoration: none;
      }
`
const StyledStatus = styled.div`
  border: 0px solid black;
  display: flex;
  justify-content: right;
  align-items: right;
  align-self: top;
  height: 50px;
  overflow: visible;
  position: relative;
  left: 20px;
  top: -15px;
`
const StyledRecipient = styled.div`
  padding-left: 10px;
  font-weight: 400;
`
const StyledBCH = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  color: #20acac;
  font-size: 20px;
`
const StyledPadding = styled.div`
  width: 85px;
`
export default Flipstarter
