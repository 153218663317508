import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components'
import { NavLink, useParams} from 'react-router-dom'
import Spacer from '../../components/Spacer'
import Papa from 'papaparse'
import ImageBCH from '../../assets/bch.png'
import ImageBack from '../../assets/back.png'
import ImageBack2 from '../../assets/back2.png'
import ImageNext from '../../assets/next.png'
import ImageNext2 from '../../assets/next2.png'

const Detailed: React.FC = () => {
  let { number } = useParams();           // assign 'number' as the current url extra
  const num: number = Number(number)        // convert number to num in case it's a string (not needed?)
  const [data, setData] = useState([]);     // spreadsheet array holding variable
  const dataLength = data.length
  const selectedRow = data[num]             // grab the specific row in the spreadsheet using the url extra
  const [fileText, setFileText] = useState(''); 
  

  let screenshotsObject
  if (selectedRow) {
    screenshotsObject = selectedRow.screenshots !== '[]' ? JSON.parse(selectedRow.screenshots) : 'noImage.png';
  }
  let formattedUSD
  if (selectedRow) {
    formattedUSD = selectedRow.usd_amount !== '0' ? Number(selectedRow.usd_amount).toLocaleString() : '0';
  }
  let statusTitle
  if (selectedRow) {
    statusTitle = selectedRow.status === 'expired' ? 'Funding Failed' : 'Amount Raised'
  }
  let otherFlips: Array<number>
  let otherFlipsTotal: number = 0
  if (selectedRow) {
    otherFlips = JSON.parse(selectedRow.otherflips)
    for (var i = 0; i < otherFlips.length; i++) {
      otherFlipsTotal = +otherFlipsTotal + +data[otherFlips[i]].bch
    }

  }

 // Read txt file from server into variable for rendering
  useEffect(() => {
    fetch('https://flipwatch.org/doc/' + number + '.htm')
      .then((response) => response.text())
      .then((text) => setFileText(text));
  }, [number]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('https://flipwatch.org/database.csv')
        const text = await response.text();
        const results = Papa.parse(text, { header: true });
        setData(results.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [])

  let fundedSplice
  let fundedLink = '#'
  if (selectedRow) {
    if (selectedRow.fundedTx !== '') {
      fundedSplice = (((selectedRow.fundedTx).slice(0,8) + '...' + (selectedRow.fundedTx).slice(56,64)) || 'notdefined')
      fundedLink = (('https://explorer.melroy.org/tx/' + selectedRow.fundedTx) || 'notdefined')
    }
  }

  const minNum = () => {
    number = number - 1;
  }
  const addNum = () => {
    console.log('addNum triggered')
    number = number + 1;
  }
  console.log(data.length)
  console.log('number ' + number)
  return (
    <Page>
      {selectedRow ? (
        <StyledDetail>
          
          {number > 0 && (
            <StyledBackButton onClick={minNum} to={`/${number - 1}`} title="Back"/>
          )}

          {(+number + +2) < dataLength && 
            <StyledNextButton onClick={addNum} to={`/${(+number + +1)}`} title="Next"/>
          }
          
          <StyledTitle>{selectedRow.title}</StyledTitle>
          <StyledScreenshot>
            {screenshotsObject === 'noImage.png' && (
              'No Image 😔'
            )}
            {screenshotsObject && screenshotsObject !== 'noImage.png' && (
              <TransparentContainer>
                {(screenshotsObject[0].image.includes('flipbackend.bitcoincash.network')) && (
                  <div key={screenshotsObject[0].image}>
                    <a href={screenshotsObject[0].image} target="_blank"><img src={'https://flipwatch.org/img/small/' + screenshotsObject[0].image.slice(57)} alt=""/></a>
                  </div>
                )}
                {(screenshotsObject[0].image.includes('flipwatch.org')) && (
                  <div key={screenshotsObject[0].image}>
                    <a href={screenshotsObject[0].image} target="_blank"><img src={'https://flipwatch.org/img/small/' + screenshotsObject[0].image.slice(25)} alt=""/></a>
                  </div>
                )}
              </TransparentContainer>
            )}
          </StyledScreenshot>

          <a href={fundedLink} target="_blank" title="See funding transaction">
            <StyledRaise>
              {statusTitle === 'Funding Failed' ? (
                <StyledRaiseFailTitle>{statusTitle}</StyledRaiseFailTitle>
              ) : (
                <StyledRaiseTitle>{statusTitle}</StyledRaiseTitle>
              )}
              {selectedRow ? (
                <StyledBCH><img src={ImageBCH} style={{height: '30px', width: '30px'}} alt="" />{selectedRow.bch} {statusTitle === 'Funding Failed' ? '' : '= $' + formattedUSD}</StyledBCH>  
              ) : (
                <></>
              )}
            </StyledRaise>
          </a>

          <StyledOtherFlips>
            <StyledRaiseTitle>All Flipstarters</StyledRaiseTitle>
            {selectedRow ? (
              otherFlips.length > 0 ? (
                otherFlips.map(flip => (
                  <StyledHighlight>
                    <StyledRaisedRow to={`/${(flip - 2)}`}>
                      <StyledRaisedTitle>{data[flip - 2].title}</StyledRaisedTitle>
                    < StyledBCH><img src={ImageBCH} style={{height: '30px', width: '30px'}} alt="" /> {data[flip - 2].bch} {data[flip - 2].status === 'expired' && ( '(Failed)' )}</StyledBCH>
                    </StyledRaisedRow>
                  </StyledHighlight>
                ))
              ) : (
                'None'
              ) 
            ) : (
              <></>
            )}
{/* need to filter out failed flipstarters from total
            {otherFlips.length > 0 &&
              <StyledTotal>
                Total <img src={ImageBCH} style={{height: '30px', width: '30px'}} alt="" /> {otherFlipsTotal}
              </StyledTotal>
            }
*/}
          </StyledOtherFlips>
{/* not sure what this would be
          <StyledAudited>
            <StyledRaiseTitle>Amount Audited</StyledRaiseTitle>
            {selectedRow ? (
              <StyledBCH><img src={ImageBCH} style={{height: '30px', width: '30px'}} alt="" />{selectedRow.bch}</StyledBCH>  
            ) : (
              <></>
            )}
          </StyledAudited>
*/}
          <StyledLastUpdate>
            <StyledRaiseTitle>Last Updated</StyledRaiseTitle>
            {selectedRow ? (
              'Never'  
            ) : (
              <></>
            )}
          </StyledLastUpdate>

          <StyledReportCard>
            <div dangerouslySetInnerHTML={{ __html: fileText }} />
          </StyledReportCard>
        </StyledDetail>
      ) : (
        <></>
      )}

      
      </Page>
  );
};

const Page = styled.div`
  max-width: 800px;
  width: 800px;
  margin: 1rem auto;
  margin-top: 40px;
  margin-bottom: 40px;

`
const StyledDetail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 0px solid red;
  text-align: center;
  width: 800px;
  font-size: 20px;
`
const StyledBackButton = styled(NavLink)`
  position: absolute;
  left: -55px;
  top: -20px;
  height: 50px;
  width: 50px;
  z-index: 2;
  background-image: url(${ImageBack});
  background-size: cover; 
  :hover {
    background-image: url(${ImageBack2});
  }
`
const StyledNextButton = styled(NavLink)`
  position: absolute;
  right: -55px;
  top: -20px;
  height: 50px;
  width: 50px;
  z-index: 2;
  background-image: url(${ImageNext});
  background-size: cover; 
  :hover {
    background-image: url(${ImageNext2});
  }
`
const StyledReportCard = styled.div`
  position: relative;
  bottom: 150px;
  right: 50px;
  border: 0px solid orange;
  text-align: left;
  width: 100%;
  max-width: 630px;
  min-height: 200px;
  font-size: 20px;
  display: flex;
  color: ${props => props.theme.text};
`
const TransparentContainer = styled.div`
  position: relative;
  border: 0px solid pink;
  &::before {
    content: "";
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
  }
`;
const StyledScreenshot = styled.div`
  position: relative;
  border: 0px solid purple;
  width: 100%;
  height: 99%;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 0.7;
  img {
    width: 100%;
  }
`
const StyledSection = styled.div`
  display: flex;
  justify-content: center;
  font-size: 28px;
  margin-bottom: 10px;
  border: solid 1px #000000;
  background-color: #20acac;
  background-size: 13px 100%;
  color: #ffffff;
  &:hover {
    background: #3ac6c6;
  }
`
const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #20acac;
  color: #ffffff;
  border: 1px solid black;
  font-size: 18px;
  font-weight: 700;
`
const StyledRaise = styled.div`
  position: absolute;
  right: -50px;
  top: 80px;
  border: 1px solid black;
  min-width: 200px;
  width: 250px;
  font-size: 24px;
  color: ${props => props.theme.text};
  background: ${props => props.theme.cardBackground};
  :hover {
    background-color: #6bffdb;
  }
`
const StyledHighlight = styled.div`
  :hover {
    background-color: #6bffdb;
  }
`
const StyledRaisedRow = styled(NavLink)`
  position: relative;
  min-width: 200px;
  width: 100%;
  font-size: 24px;
  background: #ffffff;
  color: #000000;
  text-decoration: none;
`
const StyledTotal = styled.div`
  position: relative;
  border-top: 1px solid #000000;
  padding-top: 5px;
  min-width: 200px;
  width: 100%;
  font-size: 24px;
  background: #ffffff;
  color: #000000;
  text-decoration: none;
`
const StyledRaiseTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #20acac;
  color: #ffffff;
  border-bottom: 1px solid black;
  font-size: 18px;
  font-weight: 700;
`
const StyledRaisedTitle = styled.div`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
padding-left: 5px;
  justify-content: left;
  text-align: left;
  overflow: hidden;
  width: 98%;
  background-color: #20acac;
  color: #ffffff;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  font-size: 14px;
  font-weight: 700;
`
const StyledRaiseFailTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #a60000;
  color: #ffffff;
  border-bottom: 1px solid black;
  font-size: 18px;
  font-weight: 700;
`
const StyledBCH = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  font-size: 20px;
  padding: 5px;
  color: ${props => props.theme.text};
`
const StyledOtherFlips = styled.div`
  position: absolute;
  right: -50px;
  top: 160px;
  border: 1px solid black;
  min-width: 200px;
  width: 250px;
  font-size: 24px;
  color: ${props => props.theme.text};
  background: ${props => props.theme.cardBackground};
`
const StyledAudited = styled.div`
  position: absolute;
  right: -10px;
  top: 100px;
  border: 1px solid black;
  min-width: 200px;
  width: 250px;
  font-size: 24px;
  background: #ffffff;
`
const StyledLastUpdate = styled.div`
  position: absolute;
  left: -50px;
  top: 80px;
  border: 1px solid black;
  min-width: 200px;
  width: 250px;
  font-size: 24px;
  color: ${props => props.theme.text};
  background: ${props => props.theme.cardBackground};
`
export default Detailed